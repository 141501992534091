import { combineReducers } from "redux";
import Main from "../redux/main/main_reducers";
import Home from "../redux/home/home_reducers";
import General from "../redux/general/general_reducers";
import Approvals from "../redux/approvals/approvals_reducers";
import History from '../redux/history/history_reducers'
import W09F2000 from "../redux/W0X/W09F2000/W09F2000_reducers";
import W75F4090 from "../redux/W75/W75F4090/W75F4090_reducers";
import W77F1000 from "../redux/W77/W77F1000/W77F1000_reducers";
import W77F1001 from "../redux/W77/W77F1001/W77F1001_reducers";
import W00F0010 from "../redux/W0X/W00F0010/W00F0010_reducers";
import W09F2005 from "../redux/W0X/W09F2005/W09F2005_reducers";
import W29F2006 from "../redux/W2X/W29F2006/W29F2006_reducers";
import W29F2010 from "../redux/W2X/W29F2010/W29F2010_reducers";
import W75F2000 from "../redux/W75/W75F2000/W75F2000_reducers";
import W75F2010 from "../redux/W75/W75F2010/W75F2010_reducers";
import W75F2005 from "../redux/W75/W75F2005/W75F2005_reducers";
import W21F2010 from "../redux/W2X/W21F2010/W21F2010_reducers";
import W29F2005 from "../redux/W2X/W29F2005/W29F2005_reducers";
import W29F2012 from "../redux/W2X/W29F2012/W29F2012_reducers";
import W29F2011 from "../redux/W2X/W29F2011/W29F2011_reducers";
import W29F2040 from "../redux/W2X/W29F2040/W29F2040_reducers";
import W29F2045 from "../redux/W2X/W29F2045/W29F2045_reducers";
import W09F2060 from "../redux/W0X/W09F2060/W09F2060_reducers";
import W09F2071 from "../redux/W0X/W09F2071/W09F2071_reducers";
import W09F2070 from "../redux/W0X/W09F2070/W09F2070_reducers";
import W09F2080 from "../redux/W0X/W09F2080/W09F2080_reducers";
import W09F1501 from "../redux/W0X/W09F1501/W09F1501_reducers";
import W09F2010 from "../redux/W0X/W09F2010/W09F2010_reducers";
import W09F1500 from "../redux/W0X/W09F1500/W09F1500_reducers";
import W09F9000 from "../redux/W0X/W09F9000/W09F9000_reducers";
import W09F2020 from "../redux/W0X/W09F2020/W09F2020_reducers";
import W09F2021 from "../redux/W0X/W09F2021/W09F2021_reducers";
import W09F2023 from "../redux/W0X/W09F2023/W09F2023_reducers";
import W09F2150 from "../redux/W0X/W09F2150/W09F2150_reducers";
import W21F2005 from "../redux/W2X/W21F2005/W21F2005_reducers";
import W39F1011 from "../redux/W3X/W39F1011/W39F1011_reducers";
import W51F1001 from "../redux/W5X/W51F1001/W51F1001_reducers";
import W39F1025 from "../redux/W3X/W39F1025/W39F1025_reducers";
import W25F2000 from "../redux/W2X/W25F2000/W25F2000_reducers";
import W25F2010 from "../redux/W2X/W25F2010/W25F2010_reducers";
import W25F2033 from "../redux/W2X/W25F2033/W25F2033_reducers";
import W25F2012 from "../redux/W2X/W25F2012/W25F2012_reducers";
import W25F2023 from "../redux/W2X/W25F2023/W25F2023_reducers";

import W38F2000 from "../redux/W3X/W38F2000/W38F2000_reducers";
import W38F2001 from "../redux/W3X/W38F2001/W38F2001_reducers";

import W25F2002 from "../redux/W2X/W25F2002/W25F2002_reducers";
import W25F2015 from "../redux/W2X/W25F2015/W25F2015_reducers";

import W29F2000 from "../redux/W2X/W29F2000/W29F2000_reducers";
import W29F2025 from "../redux/W2X/W29F2025/W29F2025_reducers";
import W29F2030 from "../redux/W2X/W29F2030/W29F2030_reducers";

import W39F1000 from "../redux/W3X/W39F1000/W39F1000_reducers";
import W39F1010 from "../redux/W3X/W39F1010/W39F1010_reducers";
import W39F1012 from "../redux/W3X/W39F1012/W39F1012_reducers";
import W39F1005 from "../redux/W3X/W39F1005/W39F1005_reducers";
import W39F3020 from "../redux/W3X/W39F3020/W39F3020_reducers";

import W39F2000 from "../redux/W3X/W39F2000/W39F2000_reducers";
import W39F2001 from "../redux/W3X/W39F2001/W39F2001_reducers";
import W39F1030 from "../redux/W3X/W39f1030/W39F1030_reducers";
import W25F2018 from "../redux/W2X/W25F2018/W25F2018_reducers";
import W25F6000 from "../redux/W2X/W25F6000/W25F6000_reducers";
import W25F2303 from "../redux/W2X/W25F2303/W25F2303_reducers";
import W25F1000 from "../redux/W2X/W25F1000/W25F1000_reducers";
import W09F2031 from "../redux/W0X/W09F2031/W09F2031_reducers";
import W77F1011 from "../redux/W7X/W77F1011/W77F1011_reducers";
import W77F1013 from "../redux/W7X/W77F1013/W77F1013_reducers";
import W84F3005 from "../redux/W8X/W84F3005/W84F3005_reducers";
import W09F2032 from "../redux/W0X/W09F2032/W09F2032_reducers";
import W25F2011 from "../redux/W2X/W25F2011/W25F2011_reducers";
import W00F0005 from "../redux/W0X/W00F0005/W00F0005_reducers";

import W09F3000 from "../redux/W0X/W09F3000/W09F3000_reducers";
import W09F3005 from "../redux/W0X/W09F3005/W09F3005_reducers";
import W09F4000 from "../redux/W0X/W09F4000/W09F4000_reducers";

import W89F1000 from "../redux/W8X/W89F1000/W89F1000_reducers";
import W09F3010 from "../redux/W0X/W09F3010/W09F3010_reducers";
import W25F2040 from "../redux/W2X/W25F2040/W25F2040_reducers";

import W09F2215 from "../redux/W0X/W09F2215/W09F2215_reducers";
import W09F2225 from "../redux/W0X/W09F2225/W09F2225_reducers";
import W51F1100 from "../redux/W51/W51F1100/W51F1100_reducers";
import W51F1101 from "../redux/W51/W51F1101/W51F1101_reducers";
import W25F2041 from "../redux/W2X/W25F2041/W25F2041_reducers"

import W39F3001 from "../redux/W3X/W39F3001/W39F3001_reducers";
import W39F3000 from "../redux/W3X/W39F3000/W39F3000_reducers";
import W09F2210 from "../redux/W0X/W09F2210/W09F2210_reducers";
import W09F2291 from "../redux/W0X/W09F2291/W09F2291_reducers";

import W09F2270 from "../redux/W0X/W09F2270/W09F2270_reducers";
import W09F2280 from "../redux/W0X/W09F2280/W09F2280_reducers";
import W39F3035 from "../redux/W3X/W39F3035/W39F3035_reducers";
import W09F2090 from "../redux/W0X/W09F2090/W09F2090_reducers";
import W09F2091 from "../redux/W0X/W09F2091/W09F2091_reducers";
export default combineReducers({
    main: Main,
    home: Home,
    general: General,
    approvals: Approvals,
    history: History,
    W00F0010: W00F0010,
    W09F2000: W09F2000,
    W09F2005: W09F2005,
    W09F2060: W09F2060,
    W09F2010: W09F2010,
    W09F2070: W09F2070,
    W09F2150: W09F2150,
    W29F2006: W29F2006,
    W77F1000: W77F1000,
    W77F1001: W77F1001,
    W75F4090: W75F4090,
    W75F2000: W75F2000,
    W75F2010: W75F2010,
    W75F2005: W75F2005,
    W21F2010: W21F2010,
    W29F2005: W29F2005,
    W09F2071: W09F2071,
    W09F2080: W09F2080,
    W09F1500: W09F1500,
    W09F1501: W09F1501,
    W09F9000: W09F9000,
    W09F2020: W09F2020,
    W09F2023: W09F2023,
    W21F2005: W21F2005,
    W39F1011: W39F1011,
    W38F2000: W38F2000,
    W38F2001: W38F2001,
    W51F1001: W51F1001,
    W39F1025: W39F1025,
    W25F2000: W25F2000,
    W25F2002: W25F2002,
    W29F2011: W29F2011,
    W39F1000: W39F1000,
    W29F2010: W29F2010,
    W29F2012: W29F2012,
    W29F2040: W29F2040,
    W29F2045: W29F2045,
    W29F2000: W29F2000,
    W29F2025: W29F2025,
    W39F1005: W39F1005,
    W39F1010: W39F1010,
    W39F1012: W39F1012,
    W39F2000: W39F2000,
    W39F2001: W39F2001,
    W09F2021: W09F2021,
    W39F1030: W39F1030,
    W77F1011: W77F1011,
    W77F1013: W77F1013,
    W25F2033: W25F2033,
    W25F2010: W25F2010,
    W25F2015: W25F2015,
    W25F2012: W25F2012,
    W25F6000: W25F6000,
    W25F2018: W25F2018,
    W25F2303: W25F2303,
    W25F2023: W25F2023,
    W25F2011: W25F2011,
    W25F1000: W25F1000,
    W09F2031: W09F2031,
    W84F3005: W84F3005,
    W09F2032: W09F2032,
    W29F2030: W29F2030,
    W00F0005: W00F0005,
    W09F4000: W09F4000,
    W89F1000: W89F1000,
    W09F3010: W09F3010,
    W09F3000: W09F3000,
    W09F3005: W09F3005,
    W25F2040: W25F2040,
    W09F2215: W09F2215,
    W09F2225: W09F2225,
    W51F1100: W51F1100,
    W51F1101: W51F1101,
    W25F2041: W25F2041,
    W39F3000: W39F3000,
    W39F3001: W39F3001,
    W39F3020: W39F3020,
    W09F2210: W09F2210,
    W09F2270: W09F2270,
    W09F2280: W09F2280,
    W39F3035: W39F3035,
    W09F2291: W09F2291,
    W09F2090: W09F2090,
    W09F2091: W09F2091,
});
